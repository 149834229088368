import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
} from 'state-domains/utils';
import { LOAD_STATUS_STALE } from 'state-domains/constants';
import {
    CLEAR_CHART_DATA,
    GET_CHART_DATA,
    LOAD_CHART_OPTIONS,
} from 'state-domains/types/actionTypes';
import { BaseAction } from 'state-domains/types';
import { DataError } from 'state-domains/domain/data';

import { ReportingState } from '../types';

export const INITIAL_STATE: ReportingState = {
    chartOptions: {
        items: {},
        status: LOAD_STATUS_STALE,
        error: null,
    },
    chartData: {
        releventFilters: {},
        items: [],
        status: LOAD_STATUS_STALE,
        error: null,
    },
};

const reportingLoadChartOptionsPendingReducer = (state: ReportingState): ReportingState => ({
    ...state,
    chartOptions: pendingReducer({ items: {} }),
});

const reportingLoadChartOptionsSuccessReducer = (state: ReportingState, action: BaseAction) => ({
    ...state,
    chartOptions: completeReducer({ items: action.payload }),
});

const reportingLoadChartOptionsFailReducer = (
    state: ReportingState,
    action: BaseAction<DataError>,
): ReportingState => ({
    ...state,
    chartOptions: failureReducer({
        items: state.chartOptions.items,
        error: action.payload.error,
    }),
});

const reportingLoadChartDataPendingReducer = (state: ReportingState): ReportingState => ({
    ...state,
    chartData: pendingReducer({ items: [], releventFilters: {} }),
});

const reportingLoadChartDataSuccessReducer = (state: ReportingState, action: BaseAction) => ({
    ...state,
    chartData: completeReducer({
        items: action.payload.data,
        releventFilters: action.payload.filters,
    }),
});

const reportingLoadChartDataFailReducer = (
    state: ReportingState,
    action: BaseAction<DataError>,
): ReportingState => ({
    ...state,
    chartData: failureReducer({
        items: state.chartData.items,
        releventFilters: state.chartData.releventFilters,
        error: action.payload.error,
    }),
});

const reportingClearChartData = (state: ReportingState): ReportingState => ({
    ...state,
    chartData: failureReducer({
        items: [],
        releventFilters: {},
        error: null,
    }),
});

export const reducer = mappedReducer(INITIAL_STATE, {
    [typeFail(LOAD_CHART_OPTIONS)]: reportingLoadChartOptionsFailReducer,
    [typePending(LOAD_CHART_OPTIONS)]: reportingLoadChartOptionsPendingReducer,
    [typeComplete(LOAD_CHART_OPTIONS)]: reportingLoadChartOptionsSuccessReducer,

    [typeFail(GET_CHART_DATA)]: reportingLoadChartDataFailReducer,
    [typePending(GET_CHART_DATA)]: reportingLoadChartDataPendingReducer,
    [typeComplete(GET_CHART_DATA)]: reportingLoadChartDataSuccessReducer,

    [CLEAR_CHART_DATA]: reportingClearChartData,
});
